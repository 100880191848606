import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/dashboards',
  },
  {
    path: '/dashboards',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard/Index.vue'),
    children: [
      {
        path: '',
        name: 'ListDashboards',
        component: () => import(
          /* webpackChunkName: "dashboard" */ '../views/Dashboard/ListDashboards.vue'
        ),
      },
      {
        path: ':id',
        name: 'ViewDashboard',
        component: () => import(
          /* webpackChunkName: "dashboard" */ '../views/Dashboard/ViewDashboard.vue'
        ),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: '/customers/:customerNo',
    name: 'Customer',
    component: () => import(
      /* webpackChunkName: "dashboard" */ '../views/Customer.vue'
    ),
    meta: { requiresAuth: true },
  },
  {
    path: '/favorites',
    name: 'Favorites',
    component: () => import(/* webpackChunkName: "favorites" */ '../views/Favorites.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/notes',
    name: 'Notes',
    component: () => import(/* webpackChunkName: "notes" */ '../views/Notes.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/invoices/:invoiceId',
    name: 'Invoice',
    component: () => import(/* webpackChunkName: "invoice" */ '../views/Invoice.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import(/* webpackChunkName: "search" */ '../views/Search.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/help',
    component: () => import(
      /* webpackChunkName: "help" */ '../views/Help/Index.vue'
    ),
    children: [
      {
        path: '',
        name: 'Overview',
        component: () => import(
          /* webpackChunkName: "help" */ '../views/Help/Overview.vue'
        ),
        meta: { requiresAuth: true },
      },

      {
        path: 'usingthesite',
        name: 'UsingTheSite',
        component: () => import(
          /* webpackChunkName: "help" */ '../views/Help/UsingTheSite.vue'
        ),
        meta: { requiresAuth: true },
      },

      {
        path: 'dashboards',
        name: 'Dashboards',
        component: () => import(
          /* webpackChunkName: "help" */ '../views/Help/Dashboards.vue'
        ),
        meta: { requiresAuth: true },
      },

      {
        path: 'faqs',
        name: 'FAQs',
        component: () => import(
          /* webpackChunkName: "help" */ '../views/Help/FAQs.vue'
        ),
        meta: { requiresAuth: true },
      },
      {
        path: '/changelog',
        name: 'ChangeLog',
        component: () => import(
          /* webpackChunkName: "help" */ '../views/Help/ChangeLog.vue'
        ),
      },
    ],
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/internal-server-error',
    name: 'InternalServerError',
    component: () => import(
      /* webpackChunkName: "errors" */ '../views/Errors/InternalServerError.vue'
    ),
    meta: { requiresAuth: true },
  },
  {
    path: '/forbidden',
    name: 'Forbidden',
    component: () => import(/* webpackChunkName: "errors" */ '../views/Errors/Forbidden.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: () => import(/* webpackChunkName: "errors" */ '../views/Errors/Unauthorized.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: () => import(/* webpackChunkName: "errors" */ '../views/Errors/PageNotFound.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    }
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem('access_token');
  if (
    to.matched.some((record) => record.meta.requiresAuth) && !isAuthenticated
  ) next({ name: 'Unauthorized' });
  else next();
});

export default router;
